<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Metal Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="name">Metal Adı</label>
            <InputText id="name" type="text" v-model="dataItem.name"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="nameEng">Metal Adı (İngilizce)</label>
            <InputText id="nameEng" type="text" v-model="dataItem.nameEng"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="colorCode">Renk Kodu</label>
            <InputText id="colorCode" type="text" v-model="dataItem.colorCode"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="shortName">Kısaltma</label>
            <InputText id="shortName" type="text" v-model="dataItem.shortName"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="sortOrder">Sıralama</label>
            <InputText id="sortOrder" type="text" v-model="dataItem.sortOrder"/>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
        </div>
      </Panel>

    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import ResourceService from "../../services/resourceService";

export default {
  _service: null,
  components: {AdminToast},
  created() {
    this._service = new ResourceService();
  },
  data() {
    return {
      returnField:"",
      dataItem: {
        name: "",
        isActive: "",
      }
    }
  },
  methods: {
    async save() {
      const insertResponse = await this._service.insertResource(this.dataItem);
      if(insertResponse.isSuccess)
      {
        await this.$router.push({name: "resourceEdit", params: {resourceId: insertResponse.data}});
      }
      else {
        showErrorMessage(this, "Kaynak eklemede sorun ile karşılaşıldı");
      }
    }
  }
}
</script>